export default class TR {
    static translates = {

        'Выигрывай приз как не крути':
            'Her durumda<br/>ödül kazanırsınız',

        'Крутить колесо':
            'Çarkı çevirin',

        'Крутить еще':
            'Spin more',

        'Наши поздравления! Вы выиграли':
            'Tebrikler!<br/>Kazandınız',

        'Наименование приза':
            'Ödül adı  ',

        'Вам доступно {left} - {total} попытки':
            '{left}’den {total} denemeniz var',

        'Получите заслуженный приз, обратившись в службу поддержки':
            'Destek servisiyle iletişime geçerek hak ettiğiniz ödülü alın'
    }
}
