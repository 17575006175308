import React from "react";

import MainLayout from "components/main/layout/MainLayout";
import {LanguageContextProvider} from "components/core/contexts/LanguageContext";

import 'antd/dist/antd.min.css'
import "./styles/style.scss"

class MainApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {lang: 'ru'}
    }

    componentDidMount() {
        let lang = localStorage.getItem('lang')
        if (lang !== '' && lang !== undefined && lang !== null) {
            console.log('LANG_LOC', lang)
            this.setState({'lang': lang})
        } else {
            this.setState({'lang': 'ru'})
        }
    }

    render() {
        return <LanguageContextProvider value={{lang: this.state.lang, set: (l) => this.setState({lang: l})}}>
            <MainLayout/>
        </LanguageContextProvider>
    }
}

export default MainApp;
