export default function Prise(props) {

    let items = []

    for (let i = 1; i < 2; i++) {
        items.push(<div
            key={i}
            style={{
                background: 'rgb(255, 255, 255) url(' + props.img + ') no-repeat center'
            }}
            className={'prise' + i + ' prise-item ' + (props.done ? 'bounceIn' : 'bounceOut')}></div>)
    }

    return <div className={'prises'}>
        {items}
    </div>
}
