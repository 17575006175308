import RU from "components/main/translates/RU";
import TR from "components/main/translates/TR";
import EN from "components/main/translates/EN";
import {LanguageContextConsumer} from "components/core/contexts/LanguageContext";

export default class Messages {


    static languages = {
        ru: 'Русский',
        en: 'English',
        // tr: 'Türk',
    }

    static translates = {
        ru: RU.translates,
        en: EN.translates,
        tr: TR.translates,
    }

    static t(message, params = {}) {
        return <LanguageContextConsumer>
            {
                c => {
                    let m = Messages.translates[c.lang][message]
                    if (!m)
                        m = message


                    Object.keys(params).map((i) => {
                        m = m.replaceAll('{' + i + '}', params[i])
                    })

                    return <em style={{
                        fontStyle: 'normal'
                    }} dangerouslySetInnerHTML={{__html: m}}/>
                }
            }
        </LanguageContextConsumer>
    }

}
