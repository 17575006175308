import React from "react";

import Home from "components/main/views/Home";

export default class MainLayout extends React.Component {


    // site = 'https://wbb2.arew.ru'
    site = 'https://bingo.vertera.org'

    // site = 'http://localhost:5004'

    constructor(props) {
        super(props);
        this.state = {
            gifts: {},
            total: 0,
            domain: '',
            profile: {},
            done: false,
        }
    }

    getGifts = function () {
        let url = this.site + '?u=' + window.uid + '&t=' + window.tag + '&act=get'
        fetch(url, {
            method: 'GET',
            // mode: 'cors',
            // cache: 'no-cache',
            // headers: {'Content-Type': 'application/json'},
            // redirect: 'follow',
            // referrerPolicy: 'no-referrer',
        }).then(r => r.json()).then(r => {

            let g = (r ? JSON.parse(r.body) : null)
            let u = (r ? JSON.parse(r.profile) : null)

            //console.log('get gifts, user',g)

            if (g && u) {

                let gifts = g.gifts
                if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                    console.log('FAKE gifts provided')
                    gifts = {
                        9635: {
                            gid: 100, name: "FIRST", image: "/img/product/P908E.png"
                        },
                        9636: {
                            gid: 100, name: "Visiomix Plasma Technology", image: "/img/product/P906E.png"
                        }

                    }
                }
                this.setState({
                    gifts: gifts,
                    total: g.total,
                    domain: r.server,
                    profile: u,
                    done: true
                })
            }
        });
    }

    setWin = function (id = '', cb) {

        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            console.log('fake set win', id)
            return false
        }

        if (!id) {
            alert('Что то пошло не так!, попробуйте еще.')
            return false;
        }

        let url = this.site + '?u=' + window.uid + '&t=' + window.tag + '&i=' + id + '&act=set'

        fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {'Content-Type': 'application/json'},
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }).then(r => r.json()).then(r => {
            let b = JSON.parse(r.body)
            console.log('win response', b)
            return cb?.(b)
        }).catch(function (error) {
            console.log(error)
        });
    }

    componentDidMount() {

        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            console.log('DEV MODE')
            window.uid = '100';
            window.tag = 'promo_roulette2402'
        }

        let u = window.uid
        let t = window.tag

        if (u && t && u !== 'false' && t !== 'false' && !t.startsWith('<') && !u.startsWith('<')) {
            console.log('set', u, t)
            localStorage.setItem('uid', u)
            localStorage.setItem('tag', t)
        }

        window.uid = localStorage.getItem('uid')
        window.tag = localStorage.getItem('tag')

        u = window.uid
        t = window.tag

        if (u && t && u !== 'false' && t !== 'false' && !t.startsWith('<') && !u.startsWith('<'))
            this.getGifts()
    }

    render() {
        if (!this.state.done)
            return ''
        return <Home gifts={this.state.gifts} domain={this.state.domain} total={this.state.total} app={this}/>
    }
}
