import {useState} from "react";
import Messages from "components/main/translates/Messages";
import {LanguageContextConsumer} from "components/core/contexts/LanguageContext";

export default function Languages() {

    const [open, setOpen] = useState(false)

    const change = (l, context) => {
        localStorage.setItem('lang', l)
        setOpen(!open)
        context.set(l)
    }

    return <div className="lang-switcher">

        <LanguageContextConsumer>
            {
                (c) => {
                    return Object.keys(Messages.languages).map((i, k) => {
                        return <a key={i} onClick={() => change(i, c)}
                                  className={(i === c.lang ? 'active' : 'inactive ' + (open ? 'open' : 'close'))}>
                            {Messages.languages[i]}
                        </a>
                    })
                }
            }
        </LanguageContextConsumer>
    </div>
}
