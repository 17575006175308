import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter} from "react-router-dom";

import MainApp from "components/main/MainApp";
import {ConfigProvider} from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';

class IndexClass extends React.Component {
    render() {
        return <MainApp app={this}/>
    }
}

let routerParams = {
    forceRefresh: false,
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.Fragment>
        <ConfigProvider locale={ruRU}x>
            <BrowserRouter {...routerParams}>
                <IndexClass/>
            </BrowserRouter>
        </ConfigProvider>
    </React.Fragment>
);
