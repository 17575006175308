import {useState} from "react";
import Propeller from "components/lib/Propeller";
import Messages from "components/main/translates/Messages";
import Languages from "components/main/views/Languages";
import Prise from "components/main/views/Prise";

export default function Home(props) {

    const total = props.total

    const [rotation, setRotation] = useState(false)
    const [wheel, setWheel] = useState(true)
    const [left, setLeft] = useState(Object.entries(props.gifts).length)
    // const [left, setLeft] = useState(3)
    const [current, setCurrent] = useState(0)
    const [done, setDone] = useState(false)
    const [image, setImage] = useState(null)
    const [title, setTitle] = useState(null)

    const startRotate = () => {
        if (!left)
            return false

        // setImage(null)

        let rotateElement = document.getElementById('wheel-rotation')
        let gKeys = Object.keys(props.gifts)
        let id = 0
        if (gKeys.length > 0) {
            id = gKeys[current]
            let info = props.gifts[id]
            if (info && info.image) {
                setImage(props.domain + info.image)
                setTitle(info.name)
            }
        }

        new Propeller(rotateElement, {
            speed: 500,
            inertia: .98,
            onStop: (e) => {
                setTimeout(() => {
                    setWheel(false)
                    setRotation(false)
                    setLeft(left - 1)
                    setDone(false)

                    if (gKeys.length > 0) {
                        setCurrent(current + 1)
                        props.app.setWin(id, (res) => {
                            if (res.status === 'ok')
                                console.log('ok')
                        })

                    }
                }, 1500)
                setDone(true)
            }
        })
        setRotation(true)
        blinkLights()
    }

    let neon = []

    for (let i = 1; i <= 10; i++)
        neon.push(<div key={i} className={"neon" + i + ' ' + (rotation ? 'rotate-neon' : '')}></div>)

    let showBlur = (wheel && !rotation)

    let leftInfo = Messages.t('Вам доступно {left} - {total} попытки', {
        left: left,
        total: total
    })

    let lights = []

    for (let i = 1; i <= 24; i++)
        lights.push(<div key={i} id={'blinkLight-' + i} className={'light-' + i + ' light-item '}></div>)


    const blinkLights = (i = 0) => {
        const lights = document.querySelectorAll('.active-light');
        lights.forEach(light => light.classList.remove('active-light'))
        let blinkLight = document.getElementById('blinkLight-' + i)
        if (blinkLight)
            blinkLight.classList.add('active-light')
        setTimeout(() => blinkLights((i >= 24) ? 1 : i + 1), 100)
    }

    return <div className={'main-container'}>
        <img className={'logo'} src="/img/logo.svg" alt="logo"/>
        <div className={'fio'}>
            {props.app.state.profile.name} [{Object.keys(props.app.state.profile.card)[0]}]
        </div>
        <Languages/>

        <div className="wheel">
            <div className={"info " + (wheel && !rotation ? 'bounceIn' : 'bounceOut')}>
                <h1>
                    {Messages.t('Выигрывай приз как не крути')}
                </h1>
                <br/>
                <button onClick={() => startRotate()} className={left ? '' : 'disabled'}>
                    {Messages.t('Крутить колесо')}
                </button>
            </div>

            <div className={"info " + (!wheel ? 'bounceIn' : 'dn')}>
                <h1>
                    {Messages.t('Наши поздравления! Вы выиграли')}
                </h1>
                <p>
                    {Messages.t(title)}
                </p>
                <div style={{
                    background: 'url(' + image + ') no-repeat'
                }} className={'prise'}></div>
                {
                    (
                        left ? <div>
                            <button onClick={() => setWheel(true)}>
                                {Messages.t('Крутить еще')}
                            </button>
                            <br/>
                            <br/>
                        </div> : ''
                    )
                }
                <span>
                    {Messages.t('Заслуженный приз Вы можете найти в Каталоге, в разделе "Подарки"')}
                </span>
                <div>
                    {leftInfo}
                </div>
            </div>

            <div style={{position: "absolute"}} className={'wheel-container ' + (wheel ? 'bounceIn' : 'bounceOut')}>
                <div className={(showBlur ? 'blur' : '')}></div>
                <div className={"bg " + (showBlur ? 'dn' : '')}></div>
                <div className={"wheel_logo" + (showBlur ? 'dn' : '')}></div>
                <div className={"wheel_ring" + (showBlur ? 'dn' : '')}></div>
                <div id={'wheel-rotation'} className={"wheel_items " + rotation + (showBlur ? ' dn' : '')}></div>
                <div>
                    <div className={done ? '' : 'op0'}>
                        <Prise img={image} done={true}/>
                    </div>
                    <div className={!done ? '' : 'op0'}>
                        {(rotation && wheel) ? lights : ''}
                    </div>
                </div>
            </div>
            {neon}
            {wheel ? <div className={'left'}>{leftInfo}</div> : ''}
        </div>
        <div className={'app-name'}>
            WR INTERNATIONAL
        </div>
    </div>
}
