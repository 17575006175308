export default class EN {
    static translates = {

        'Выигрывай приз как не крути':
            'Win a prize<br/>no matter how you slice it  ',

        'Крутить колесо':
            'Spin the wheel  ',

        'Крутить еще':
            'Spin more',

        'Наши поздравления! Вы выиграли':
            'Congratulations!<br/>You won  ',

        'Наименование приза':
            'Name of the prize  ',

        'Вам доступно {left} - {total} попытки':
            '{left} out of {total} attempts are available  ',

        'Заслуженный приз Вы можете найти в Каталоге, в разделе "Подарки"':
            'You can find your well-deserved prize in the Catalog, in the "Gifts" section'
    }
}
