export default class RU {
    static translates = {

        'Выигрывай приз как не крути':
            'Выигрывай приз<br/>как не крути',

        'Крутить колесо':
            'Крутить колесо',

        'Наши поздравления! Вы выиграли':
            'Наши поздравления!<br/>Вы выиграли',

        'Наименование приза':
            'Наименование приза',

        'Вам доступно {left} - {total} попытки':
            'Попыток доступно : {left} из {total} ',

        'Заслуженный приз Вы можете найти в Каталоге, в разделе "Подарки"':
            'Заслуженный приз Вы можете найти в Каталоге, в разделе "Подарки"'
    }
}
